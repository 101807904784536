<template>
  <div class="fill-library-dropdown" :class="{'error-wrapper':notificationError, 'only-mobile-view':onlyMobileView}">
    <div
        class="fill-library-dropdown__select"
        :class="{'active':show}"
        ref="selectedField"
        @click="changeVisible"
    >
      <template  v-if="currentParam.value">
        <div class="fill-selected-preview">
          <div class="image-preview-wrapper" v-if="currentParam.type==='image'">
            <img class="image-preview-element" :src="currentParam.value" />
          </div>
          <icon-component
              v-else
              :icon="'/default-icons/color.svg'"
              :color="currentParam.value"
              :size="20"
          />
        </div>
        <div class="fill-selected-title">
          <span class="selected-value" v-if="currentParam.type==='image'">{{labelImg}}</span>
          <span class="selected-value" v-else>{{currentParam.value}}</span>
        </div>
      </template>
      <template v-else>
        <div class="fill-selected-title">
          <span>{{$t('НеУказано')}}</span>
        </div>
      </template>
      <div class="visible-change-list" :class="{'active':show}">
        <icon-component
            :icon="'/default-icons/expand_more.svg'"
            :color="'var(--colorIconSecondary)'"
            :size="20"
        />
      </div>
    </div>
    <div class="fill-options-wrapper" :class="{'options-position-top':topPosition}" v-if="show" v-on-clickaway="cancelChanges">
      <div class="fill-options" @mousedown.self="cancelChanges" @touchstart.self="cancelChanges" ref="containerFill">
        <div class="fill-options__container">
          <fill-component v-model="currentParam" @close="cancelChanges" :type-fill="typeFill"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import IconComponent from "@/components/redesign/iconComponent.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import FillComponent from "@/components/redesign/FillComponent.vue";
/**
 * @prop {Object} value - Объект {type:'',value:''}, где type==='color', либо type==='image' в зависимости от выбранных данных
 * @prop {Boolean} notificationError - Стили ошибки
 * @prop {Boolean} onlyMobileView - Мобильная версия поля выбранных данных
 * @prop {Boolean} topPosition - Открывает опции сверху поля выбранных данных
 * @prop {String} typeFill - Тип заливки. Имеет 2 значения: 'color', 'image'. Передаём в случае, если хотим, чтобы скрылись табы и отображался только один раздел (один тип заливки).
 * !!!Инъекции:
 * @prop {String} typePalette - Палитра цветов. Имеет 2 значения: 'custom', 'accent'.
 * @prop {Boolean} pipette - отображение пипетки при выборе цвета
 * @prop {Boolean} opacity - отображение ползунка с прозрачность цвета
 * @prop {Boolean} library - отображение библиотеки для выбора изображения
 *
 */
export default {
  name: "FillDropdown",
  components: {FillComponent, DefaultButton, IconComponent},
  props:  {
    value:{
      type:Object,
      default(){
        return{
          type:'',
          value:''
        }
      }
    },
    notificationError:{
      type: Boolean,
      default:false
    },
    onlyMobileView:{
      type:Boolean,
      default:false
    },
    topPosition:{
      type:Boolean,
      default: false
    },
    typeFill:{
      type:String,
      default: ''
    },
    typePalette: {
      type: String,
      default: 'custom',
    },
    pipette:{
      type: Boolean,
      default: false
    },
    opacity:{
      type: Boolean,
      default: true
    },
    library:{
      type: Boolean,
      default: false
    }
  },
  provide(){
    return{
      typePalette:this.typePalette,
      pipette:this.pipette,
      opacity:this.opacity,
      library:this.library
    }
  },
  data () {
    return {
      show: false,
      currentParam:{
        type:'',
        value:''
      }
    }
  },
  mounted () {
    this.initialization()
  },
  methods: {
    cancelChanges(){
      this.show=false
    },
    initialization(){
      if(this.value?.value){
        this.currentParam=this.value
      }else {
        if((!this.typeFill||this.typeFill==='color')&&!this.value.value){
          //Если у нас отсутствует typeFill, то это значит, что будет 2 таба: цвет и картинка, из которых по дефолту будет стоять цвет.
          // Либо если выбран только typeFill==='color', значит будет только таб цвета. И при этом значение отсутствует, то мы поставим дефолтное значение тз палитры
          let defaultValue
          if(this.typePalette==='accent') defaultValue = this.$store.getters.getAccentFillVariables[0]
          else defaultValue = this.$store.getters.getCustomFillVariables[0]
          this.$emit('input', {type:'color', value:defaultValue})
        } else if(this.typeFill==='image'){
          //Если у нас только таб картинок, то мы по дефолту установим тип image
          this.$emit('input', {type:'image', value:''})
        }
      }
    },
    changeVisible(){
      this.show=!this.show
      if(this.show) setTimeout(()=>{ //fillComponent любит жёсткие значение ширины в px, поэтому пришлось добавить отслеживание размера "инпута" и присваивание этих размеров fillComponent
        if(window.innerWidth>743){
          this.$refs.containerFill.style.width=this.$refs.selectedField.clientWidth+'px'
          this.$refs.containerFill.style.display='block'
        } else {
          this.$refs.containerFill.style.width=window.innerWidth+'px'
          this.$refs.containerFill.style.display='flex'
        }
      },0)
    },
  },
  computed: {
    labelImg() {
      if (!this.currentParam.value||(this.currentParam.value&&this.currentParam.type!=='image')) { //Если отсутствует значение, либо если текущий тип это color, то возвращаем falsy значение, так как тут обрабатывается ссылка на картинку
        return undefined;
      }

      const urlParts = this.currentParam.value?.split('/')
      return urlParts[urlParts.length - 1];
    },
  },

  watch:{
    value:{
      handler(newValue){
        if(this.currentParam.type!==newValue.type||this.currentParam.value!==newValue.value){
          this.currentParam=newValue
        }
      }, deep:true
    },
    currentParam:{
      handler(newValue){
        if(this.value.type!==newValue.type||this.value.value!==newValue.value){
          this.$emit('input', newValue)
        }
      }, deep:true
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes show-modal {
  0%{
    transform: translate3d(0px, 100%, 0px);
  }
  100%{
    transform: translate3d(0px, 0, 0px);
  }
}
@keyframes show-back {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@mixin mobileView(){
  .fill-library-dropdown__select{
    padding: 14px 16px;
    .fill-selected-title{
      font-size: 16px;
      line-height: 20px; /* 128.571% */
    }
  }
}
.fill-library-dropdown{
  min-width: 150px;
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  &.error-wrapper{
    .fill-library-dropdown__select{
      border: 0.5px solid var(--colorStrokeNegative);
      background: var(--colorBackgroundNegativeTint);
      &:hover{
        background: var(--colorBackgroundNegativeTintHover);
      }
      &.active{
        border: 0.5px solid var(--colorStrokeNegative);
        background: var(--colorComponentFieldBackgroundActive);
        &:hover{
          background: var(--colorComponentFieldBackgroundActive);
        }
      }
    }
  }
  &.only-mobile-view{
    @include mobileView;
  }
  .fill-library-dropdown__select{
    width: 100%;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 8px;
    background-color: var(--colorComponentFieldBackground);
    border: 0.5px solid var(--colorComponentFieldStroke);
    transition: background 0.3s, border 0.3s;
    &:hover{
      background-color: var(--colorComponentFieldBackgroundHover);
    }
    &.active{
      border: 0.5px solid var(--colorStrokeAccentExtraInvariably);
      background: var(--colorComponentFieldBackgroundActive);
    }
    .fill-selected-preview{
      .image-preview-wrapper{
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        width: 20px;
        height: 20px;
        display: flex;
        .image-preview-element{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }
    .fill-selected-title{
      overflow: hidden;
      color: var(--colorTextSecondary);
      font-variant-numeric: lining-nums tabular-nums;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.035px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      .selected-value{
        color: var(--colorTextPrimary);
      }
    }
    .visible-change-list{
      transition: all 0.3s;
      transform: rotate(0);
      &.active{
        transform: rotate(-180deg);
      }
    }
  }
  .fill-options-wrapper{
    position: absolute;
    z-index: 1000;
    top:calc(100% + 8px);
    left: 0;
    width: 100%;
    min-width: min-content;
    .fill-options{
      display: none;
      .fill-options__container{
        border-radius: 12px;
        border: 0.5px solid var(--colorStrokeSecondary);
        background-color: var(--colorBackgroundContent);
        /* Light/Elevation M */
        box-shadow: 0px 8px 16px 0px rgba(23, 23, 28, 0.05), 0px 0px 8px 0px rgba(30, 30, 36, 0.10);
        text-transform: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
    &.options-position-top{
      bottom: calc(100% + 8px);
      top: auto;
    }
  }
}
@media screen and (max-width: 1365px){
  .fill-library-dropdown{
    @include mobileView;
  }
}
@media screen and (max-width: 743px){
  .fill-library-dropdown{
    .fill-options-wrapper{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3500;
      background: var(--colorOverlay);

      animation-name:show-back;
      animation-duration: 0.5s;
      &.options-position-top{
        bottom: 0;
        top: 0;
      }
      .fill-options{
        padding: 16px;
        height: 100%;
        width: 100%;
        display: flex;
        overflow: auto;

        animation-name:show-modal ;
        animation-duration: 0.5s;
        .fill-options__container{
          margin: auto auto 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
