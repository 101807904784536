<script>
import dropdownComponent from "@/components/dropdown.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";
import priceFormat from "@/mixins/priceFormat";
import OptionsRow from "@/components/redesign/Table/OptionsRow.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import FunctionsTable from "@/components/redesign/Table/FunctionsTable.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import TableComponent from "@/components/redesign/Table/tableComponent.vue";
import axios from "axios";

export default {
  name: "MarkupsLayout",
  components: {
    TableComponent,
    IconComponent,
    FunctionsTable,
    DefaultButton,
    OptionsRow,
    ModalContainer, dropdownComponent},
  mixins: [priceFormat],
  data() {
    return {
      markups:[
        {
          "id": 1,
          "name": "ЖК «Альфа»",
          "children": [
            {
              "id": 1292153,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132313,
                132314,
                132315,
                132316,
                132317,
                132318,
                132319,
                132320,
                132321,
                132322,
                132323,
                132325,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": 10,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": -1,
              "banks": [
                1341
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              rooms: [1],
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-09-19 10:08:23",
              "updated_at": "2024-12-05 17:00:34",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": 0,
              "fixed_monthly_payment": null,
              "turn_off_top": 0,
              "indexation_percent": 0
            },
            {
              "id": 56354,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132313,
                132314,
                132315,
                132316,
                132318,
                132317,
                132319,
                132320,
                132321,
                132322,
                132325,
                132323,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": null,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": 0,
              "banks": [
                1340
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              "rooms": null,
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-04-22 10:44:09",
              "updated_at": "2024-09-19 10:12:21",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": 0,
              "fixed_monthly_payment": null,
              "turn_off_top": 0,
              "indexation_percent": 0
            },
            {
              "id": 57234,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132314,
                132313,
                132315,
                132316,
                132317,
                132318,
                132319,
                132321,
                132320,
                132322,
                132323,
                132325,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": null,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": 0,
              "banks": [
                930
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              "rooms": null,
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-04-23 15:20:18",
              "updated_at": "2024-12-05 17:24:25",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": null,
              "fixed_monthly_payment": null,
              "turn_off_top": null,
              "indexation_percent": 0
            }
          ]
        },
        {
          "id": 2,
          "name": "ЖК «Горный квартал»",
          "children": [
            {
              "id": 24679,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132313,
                132314,
                132315,
                132316,
                132317,
                132318,
                132319,
                132320,
                132321,
                132322,
                132323,
                132325,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": null,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": -1,
              "banks": [
                1338
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              "rooms": null,
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-02-19 19:28:52",
              "updated_at": "2024-09-19 10:03:44",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": 0,
              "fixed_monthly_payment": null,
              "turn_off_top": 0,
              "indexation_percent": 0
            },
            {
              "id": 56354,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132313,
                132314,
                132315,
                132316,
                132318,
                132317,
                132319,
                132320,
                132321,
                132322,
                132325,
                132323,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": null,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": 0,
              "banks": [
                1340
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              "rooms": null,
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-04-22 10:44:09",
              "updated_at": "2024-09-19 10:12:21",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": 0,
              "fixed_monthly_payment": null,
              "turn_off_top": 0,
              "indexation_percent": 0
            },
            {
              "id": 57234,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132314,
                132313,
                132315,
                132316,
                132317,
                132318,
                132319,
                132321,
                132320,
                132322,
                132323,
                132325,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": null,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": 0,
              "banks": [
                930
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              "rooms": null,
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-04-23 15:20:18",
              "updated_at": "2024-12-05 17:24:25",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": null,
              "fixed_monthly_payment": null,
              "turn_off_top": null,
              "indexation_percent": 0
            }
          ]
        },
        {
          "id": 4,
          "name": "ЖК «Дыхание»",
          "children": [
            {
              "id": 24679,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132313,
                132314,
                132315,
                132316,
                132317,
                132318,
                132319,
                132320,
                132321,
                132322,
                132323,
                132325,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": null,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": -1,
              "banks": [
                1338
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              "rooms": null,
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-02-19 19:28:52",
              "updated_at": "2024-09-19 10:03:44",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": 0,
              "fixed_monthly_payment": null,
              "turn_off_top": 0,
              "indexation_percent": 0
            },
            {
              "id": 56354,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132313,
                132314,
                132315,
                132316,
                132318,
                132317,
                132319,
                132320,
                132321,
                132322,
                132325,
                132323,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": null,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": 0,
              "banks": [
                1340
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              "rooms": null,
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-04-22 10:44:09",
              "updated_at": "2024-09-19 10:12:21",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": 0,
              "fixed_monthly_payment": null,
              "turn_off_top": 0,
              "indexation_percent": 0
            },
            {
              "id": 57234,
              "object_id": 24,
              "number": null,
              "developments": [
                132312,
                132314,
                132313,
                132315,
                132316,
                132317,
                132318,
                132319,
                132321,
                132320,
                132322,
                132323,
                132325,
                132326
              ],
              "typeObject": "Квартира",
              "type": "Наценка",
              "sum": null,
              "percent": null,
              "kv1": null,
              "kv2": null,
              "kv3": null,
              "min_sum": null,
              "bpv": 0,
              "banks": [
                930
              ],
              "floors": null,
              "square_min": null,
              "square_max": null,
              "price_min": null,
              "price_max": null,
              "rooms": null,
              "planning_principle": null,
              "sorts": null,
              "sections": null,
              "created_at": "2024-04-23 15:20:18",
              "updated_at": "2024-12-05 17:24:25",
              "deleted_at": null,
              "fixed_monthly_payment_is_present": null,
              "fixed_monthly_payment": null,
              "turn_off_top": null,
              "indexation_percent": 0
            }
          ]
        }
      ],
      liters:{
        "132312": "Корпус 5",
        "132313": "Корпус 3",
        "132314": "Корпус 7",
        "132315": "Корпус 8",
        "132316": "Корпус 11",
        "132317": "Корпус 12",
        "132318": "Корпус 13",
        "132319": "Корпус 10",
        "132320": "Корпус 9",
        "132321": "Корпус 6",
        "132322": "Корпус 15",
        "132323": "Корпус 14",
        "132324": null,
        "132325": "Корпус 1",
        "132326": "Корпус 2"
      },
      banks: {},
      searchQuery: null,
      activeTab: {},
      tabs: [
        {
          value: this.$i18n.t('Актуальное'),
          id: 1,
          icon: '/default-icons/task.svg'
        },
        {
          value: this.$i18n.t('Архив'),
          id: 2,
          icon: '/default-icons/history.svg'
        }
      ]
    }
  },
  methods:{
    loadMarkups() {

      axios.get('/api/get-calculator-programs').then(response => {
        const banks = response.data
        const temp = {}
        const temp2 = []
        banks.forEach((bank) => {
          temp[bank.id] = '['+bank.id+'] '+bank.name
          temp2.push({
            id: bank.id,
            text: '['+bank.id+'] '+bank.name
          })
        })
        this.banks = temp
      })
    },
    addMarkup() {
      this.$router.push({name: 'save-markups'})
    },
    editMarkup(markup) {
      this.$router.push({name: 'save-markups', props: markup})
    },
    confirmActionMarkup() {

    },
    getComplex (developments) {
      const array =[]
      developments.forEach(val => {
        if(val && this.liters[val]) {
          array.push(this.liters[val])
        }
      })
      return array.join(', ')
    },
    getPrograms (banks) {
      const array =[]
      banks.forEach(val => {
        if(val && this.banks[val]) {
          array.push(this.banks[val])
        }
      })
      return array.join(', ')
    },
  },
  mounted() {
    this.activeTab = this.tabs[0]
    this.loadMarkups()
  }
}
</script>

<template>
  <div class="markups-calc">
    <functions-table
        :tabs="tabs"
        @update:activeTab="(value) => activeTab = value"
        @update:searchQuery="(value) => searchQuery = value"
        @add-row="addMarkup"
    >
    </functions-table>
    <table-component
        v-model="markups"
        :colspan="11"
    >
      <template #header-table>
        <th>{{ $t('тип') }}</th>
        <th>{{ $t('Значение') }}</th>
        <th>{{ $t('ТипОбъекта') }}</th>
        <th>{{ $t('Строение') }}</th>
        <th>{{ $t('Секция') }}</th>
        <th>{{ $t('ось') }}</th>
        <th>{{ $t('Этаж') }}</th>
        <th>{{ $t('Комнатность') }}</th>
        <th>{{ $t('Площадь') }}</th>
        <th>{{ $t('бпв') }}</th>
        <th>{{ $t('Программы') }}</th>
      </template>
      <template #title-options="{ el }">
        <default-button
            @click-element=""
            type="outline"
            size="medium"
            leftIcon="/default-icons/info_fill.svg"
        />
      </template>
      <template #content-table-title="{ el }">
        {{el.name}}
      </template>
      <template #content-table-child="{child}">
        <td>{{child.type}}</td>
        <td>
          <template v-if="child.sum">{{priceFormat(child.sum)}}/{{ $t('м²') }}</template>
          <template v-else-if="child.percent">{{child.percent}}%/{{ $t('м²') }}</template>
          <template v-if="child.kv1">{{ $t('КВ') }}1 {{child.kv1}}%/{{ $t('м²') }}<br></template>
          <template v-if="child.kv2">{{ $t('КВ') }}2 {{child.kv2}}%/{{ $t('м²') }}</template>
          <template v-if="child.kv3">{{$t('Наценка')}} {{child.kv3}}%/{{ $t('м²') }}</template>
        </td>
        <td>{{child.typeObject}}</td>
        <td><template v-if="child.developments">{{getComplex(child.developments)}}</template></td>
        <td><template v-if="child.sections">{{child.sections.join(', ')}}</template></td>
        <td><template v-if="child.sorts">{{child.sorts.join(', ')}}</template></td>
        <td><template v-if="child.floors">{{child.floors.join(', ')}}</template></td>
        <td><template v-if="child.rooms">{{child.rooms.join(', ')}}</template></td>
        <td>
          <template v-if="child.square_min">{{child.square_min}}</template>
          <template v-if="child.square_max"> - {{child.square_max}}</template></td>
        <td>
          <template v-if="child.bpv === 0">{{$t('НеПрименяется')}}</template>
          <template v-if="child.bpv === -1">{{$t('Неважно')}}</template>
          <template v-if="child.bpv === 1">{{$t('Применяется')}}</template>
        </td>
        <td><template v-if="Object.keys(banks).length && child.banks">{{getPrograms(child.banks)}}</template></td>
        <td class="options">
          <options-row
              :activeTab="activeTab?.id"
              @read-more="editMarkup(child)"
              @archive="confirmActionMarkup(child)"
          />
        </td>
      </template>
    </table-component>
  </div>
</template>

<style scoped lang="scss">
.markups-calc{

}
</style>