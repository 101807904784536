<template>
  <div class="fill-wrapper" v-if="currentTab">
    <div class="header-fill">
      <div class="header-fill__title">{{$t('Заливка')}}</div>
      <icon-component
          @click-element="$emit('close')"
          :clickability="true"
          :icon="'/default-icons/close.svg'"
          :color="'var(--colorIconAccent)'"
          :size="16"
      />
    </div>
    <template v-if="!typeFill">
      <div>
        <default-tabs
            mode="secondary"
            :tabs="tabs"
            v-model="currentTab"
            size="small"
        ></default-tabs>
      </div>
      <div class="fill-separator">
        <div class="fill-separator__line"></div>
      </div>
    </template>
    <color-picker
        v-model="layer.color"
        v-if="currentTab.id==='color'"
    ></color-picker>
    <graphic-editor-image-loader
        v-else
        v-model="layer.source"
    ></graphic-editor-image-loader>
  </div>
</template>

<script>
import ColorPicker from "@/components/redesign/colorPickerComponent/colorPicker.vue";
import GraphicEditorImageLoader from "@/components/redesign/graphicEditorComponents/graphicEditorImageLoader.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import DefaultTabs from "@/components/redesign/defaultTabs.vue";
/**
 * @prop {Object} value - Объект {type:'',value:''}, где type==='color', либо type==='image' в зависимости от выбранных данных
 * @prop {String} typeFill - Тип заливки. Имеет 2 значения: 'color', 'image'. Передаём в случае, если хотим, чтобы скрылись табы и отображался только один раздел (один тип заливки).
 */
export default {
  name: "FillComponent",
  components: {DefaultTabs, IconComponent, GraphicEditorImageLoader, ColorPicker},
  props: {
    typeFill:{
      type:String,
      default: ''
    },
    value:{
      type:Object,
      default(){
        return{
          type:'',
          value:''
        }
      }
    },
  },
  data(){
    return{
      layer:{
        color:'',
        source:''
      },
      tabs:[
        {
          value: this.$i18n.t('Цвет'),
          id: 'color',
          icon: '/default-icons/color.svg'
        },
        {
          value: this.$i18n.t('Изображение'),
          id: 'image',
          icon: '/default-icons/panorama.svg'
        },
      ],
      currentTab: null
    }
  },
  watch:{
    layer:{
      handler(newVal){
        if(this.currentTab.id==='color'){
          this.$emit('input',{type:'color', value:newVal.color} )
        } else if(this.currentTab.id==='image') {
          this.$emit('input',{type:'image', value:newVal.source} )
        }
      },
      deep:true
    },
    currentTab:{
      handler(newVal){
        if(newVal.id==='color'){
          this.$emit('input',{type:'color', value:this.layer.color} )
        } else if(newVal.id==='image') {
          this.$emit('input',{type:'image', value:this.layer.source} )
        }
      },
      deep:true
    },
    value:{
      handler(newVal){
        if(newVal.value){
          if(newVal.type==='color'){
            this.layer.color=newVal.value
          } else if(newVal.type==='image') {
            this.layer.source=newVal.value
          }
        }
      },
      deep:true
    }
  },
  mounted() {
    if(this.typeFill==='image'||this.value.type==='image'){
      this.currentTab=this.tabs[1]
      this.layer.source=this.value.value
    } else if(this.value.type==='color'){
      this.currentTab=this.tabs[0]
      this.layer.color=this.value.value
    }
  }
}
</script>

<style scoped lang="scss">
.fill-wrapper{
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 8px;
  .header-fill{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-fill__title{
      color: var(--colorTextSecondary);
      font-variant-numeric: lining-nums tabular-nums;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.035px;
    }
  }
  .fill-separator{
    display: flex;
    height: 4px;
    justify-content: center;
    align-items: center;
    .fill-separator__line{
      background: var(--colorStrokeSecondary);
      height: 1px;
      width: 100%;
    }
  }
}
</style>