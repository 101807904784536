<script>
import DefaultTabs from "@/components/redesign/defaultTabs.vue";
import axios from "axios";
import FunctionsTable from "@/components/redesign/Table/FunctionsTable.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import TableComponent from "@/components/redesign/Table/tableComponent.vue";
import Loader from "@/components/Loader.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import OptionsRow from "@/components/redesign/Table/OptionsRow.vue";
import TableRowChips from "@/components/redesign/Table/tableRowChips.vue";

export default {
  name: "ProjectsLayout",
  components: {
    TableRowChips,
    OptionsRow, IconComponent, Loader, TableComponent, DefaultButton, FunctionsTable, DefaultTabs},
  data() {
    return {
      isLoadingTable: false,
      bankingPrograms: [],
      searchQuery: null,
      tabsTypesObjects: [],
      complexList: [],
      currentTab: {},
      tabs: [
        {
          value: this.$i18n.t('Проекты'),
          id: 'projects',
          icon: '/default-icons/layout_project.svg',
        },
        {
          value: this.$i18n.t('Строения'),
          id: 'developments',
          icon: '/default-icons/other_houses.svg',
        }
      ],
      activeTab: {},
    }
  },
  watch: {
    currentTab() {
      this.isLoadingTable = true
      this.getProgramsByBanks()
          .then(() => {
            this.isLoadingTable = false
          })
    }
  },
  methods: {
    editProgram(program) {
      this.$router.push({name: 'save-programs', params: {program: program}})
    },
    async getComplexes() {
      const res = await axios.get('complex/get-all')
          .then(res => res.data)
          .catch(e => [])

      this.complexList = res
    },
    async getTypesObjects() {
      const res = await axios.get('types-objects/get-all')
          .then(res => res.data)
          .catch(e => [])

      this.tabsTypesObjects = res
    },
    async getProgramsByBanks() {
      const res = await axios.get('calculator/programs/get-all-by-banks', {
        params: {
          mode: this.activeTab?.id,
          type_object_ids: [this.currentTab.id]
        }
      })
          .then(res => res.data)
          .catch(e => [])
      this.bankingPrograms = res ?? []
    },
    getIconByComplex(program, complex) {
      const idsCount = complex.developments.filter(el => program.developments_ids.includes(el.external_id)).length
      if(!idsCount) {
        return '/default-icons/zoom_out.svg'
      }
      if(idsCount === complex.developments.length) {
        return '/default-icons/done_all.svg'
      }
      return '/default-icons/done.svg'
    },
    getColorByComplex(program, complex) {
      return complex.developments.filter(el => program.developments_ids.includes(el.external_id)).length
          ? 'var(--colorIconAccent)'
          : 'var(--colorIconSecondary)'
    },
    getChipsByDevelopments(program, complex) {
      return complex.developments.filter(el => program.developments_ids.includes(el.external_id)).map(el => el.name)
    }
  },
  async mounted() {
    await this.getTypesObjects()

    this.currentTab = this.tabsTypesObjects[0]
    this.isLoadingTable = true
    await this.getComplexes()
    await this.getProgramsByBanks()
    this.isLoadingTable = false
  }
}
</script>

<template>
<div class="projects">
  <div class="types">
    <default-tabs
        mode="primary"
        size="medium"
        :tabs="tabsTypesObjects"
        :padding="true"
        v-model="currentTab"
    />
  </div>
  <functions-table
      :visible-btn-add-row="false"
      @update:activeTab="(value) => this.activeTab = value"
      @update:searchQuery="(value) => this.searchQuery = value"
      :tabs="tabs"
      :visible-settings-table="false"
  >
  </functions-table>

  <table-component
      v-if="!isLoadingTable"
      v-model="bankingPrograms"
      :colspan="1 + complexList.length"
  >
    <template #header-table>
      <th>Программы</th>
      <th v-for="complex of complexList">{{complex.name}}</th>
      <th class="options"></th>
    </template>
    <template #content-table-title="{ el }">
      {{el.name}}
    </template>
    <template #content-table-child="{child}">
      <td>{{child.name}}</td>
      <td v-for="complex of complexList">
        <table-row-chips
            v-if="activeTab.id === 'developments'"
            :chips="getChipsByDevelopments(child, complex)"
        />
        <icon-component
            v-else
            :icon="getIconByComplex(child, complex)"
            :color="getColorByComplex(child, complex)"
            :size="24"/>
      </td>
      <td class="options">
        <default-button
            @click-element="editProgram(child)"
            type="tertiary"
            size="medium"
            leftIcon="/default-icons/info_fill.svg"
        >{{$t('Подробнее')}}</default-button>
      </td>
    </template>
  </table-component>
  <loader
      text="Загрузка"
      v-else
  />
</div>
</template>

<style scoped lang="scss">
.projects {
  .types {
    padding: 8px 0;
  }

}
</style>