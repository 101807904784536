<template>
  <div class="langPosition">
    <button
      href="#"
      class="langDropdownButton"
      @click="toggleVisibility"
      @keydown.space.exact.prevent="toggleVisibility"
      @keydown.esc.exact="hideDropdown"
      @keydown.shift.tab="hideDropdown"
      @keydown.up.exact.prevent="startArrowKeys"
      @keydown.down.exact.prevent="startArrowKeys"
    >
      <img :src="`/images/flag_${$i18n.locale}.svg`" alt="flag" class="flagShadow" />
    </button>
    <transition name="dropdown-fade">
      <div
        v-if="isVisible"
        ref="dropdown"
        v-on-clickaway="hideDropdown"
        class="langBox"
      >
        <div v-for="(lang, idx) in langs" :key="idx">
          <div
            class="langHover"
            @click.prevent="setLocale(lang.shortName)"
            @keydown.up.exact.prevent
            @keydown.tab.exact="focusNext(false)"
            @keydown.down.exact.prevent="focusNext(true)"
            @keydown.esc.exact="hideDropdown"
          >
            <div class="localImg"><img :src="lang.flag" :alt="lang.longName"/></div>
            <div class="localLang">{{ lang.localName }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'LanguageSwitcher',
  data () {
    return {
      isVisible: false,
      focusedIndex: 0,
      langs: [
        {
          shortName: 'ru',
          longName: 'Russian',
          localName: 'Русский',
          flag: '/images/flag_ru.svg'
        },
        {
          shortName: 'en',
          longName: 'English',
          localName: 'English',
          flag: '/images/flag_en.svg'
        },
        {
          shortName: 'hy',
          longName: 'Հայերեն',
          localName: 'Հայերեն',
          flag: '/images/flag_hy.svg'
        },
        {
          shortName: 'tr',
          longName: 'Türkçe',
          localName: 'Türkçe',
          flag: '/images/flag_tr.svg'
        },
        {
          shortName: 'th',
          longName: 'แบบไทย',
          localName: 'แบบไทย',
          flag: '/images/flag_th.svg'
        },
        {
          shortName: 'kk',
          longName: 'Қазақ',
          localName: 'Қазақ',
          flag: '/images/flag_kk.svg'
        },
        {
          shortName: 'ka',
          longName: 'ქართული',
          localName: 'ქართული',
          flag: '/images/flag_ka.svg'
        },
        {
          shortName: 'az',
          longName: 'Azərbaycan',
          localName: 'Azərbaycan',
          flag: '/images/flag_az.svg'
        },
        {
          shortName: 'fa',
          longName: 'فارسی',
          localName: 'فارسی',
          flag: '/images/flag_fa.svg'
        },
        {
          shortName: 'ar',
          longName: 'عرب',
          localName: 'عرب',
          flag: '/images/flag_ar.svg'
        },
      ]
    }
  },
  methods: {
    toggleVisibility () {
      this.isVisible = !this.isVisible
    },
    hideDropdown () {
      this.isVisible = false
      this.focusedIndex = 0
    },
    startArrowKeys () {
      if (this.isVisible) {
        this.$refs.dropdown.children[0].children[0].focus()
      }
    },
    focusPrevious (isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusNext (isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusItem () {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus()
    },
    setLocale (locale) {
      localStorage.setItem('lang', locale)
      window.location.reload()
      /*this.$i18n.locale = locale
      axios.defaults.headers.common['Language'] = this.$i18n.locale
      localStorage.setItem('lang', locale)
      if (locale){
        let lang=locale
        if(lang==='hy')this.$store.dispatch('SET_MASK_PHONE', '+999(99)99-99-99')
        else if(lang==='ru')this.$store.dispatch('SET_MASK_PHONE', '+9(999)999-99-99')
      }
      this.$root.setMenu()
      this.$root.getAuth()
      // Uncomment the below lines if you need language prefixes to be shown in the URL
      // example: en.website.com (additional router configuration required)
      // this.$router.push({
      //   params: { lang: locale }
      // })
      this.hideDropdown()*/
    }
  }
}
</script>

<style lang="scss">
.langPosition {
  z-index: 3500;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 30px;
  left: 5px;

}
.langBox {
  user-select: none;
  position: absolute;
  bottom: 50px;
  left: 0;
  background-color: var(--white-basic);
  box-shadow: 0px 3px 6px rgb(204, 210, 235);
}
.localImg{
  width: 30px;
  height: 30px;
  img{
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
.localLang {
  margin-top: 3px;
  font-size: 18px;
  padding-left: 15px;
  font-weight: 400;
  vertical-align: middle;
}
.dropdown-fade-enter-active,
.dropdown-fade-leave-active {
  transition: all 0.1s ease-in-out;
}
.dropdown-fade-enter,
.dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}
.langDropdownButton {
  outline: none!important;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}
.shortName {
  margin-top: 5px;
}
.flag {
  width: 30px;
}
.flagShadow {
  border-radius: 50px;
  box-shadow: 0px 3px 3px rgb(204, 210, 235);
  width: 35px;
}
.langHover {
  cursor: pointer;
  display: flex;
  margin: 10px;
  text-decoration: none;
  color: var(--lang-color);
  transition: all 0.1s ease-in-out;
  &:hover {
    color: #007aff;
  }
}
</style>
