<script>
import OptionsRow from "@/components/redesign/Table/OptionsRow.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import FunctionsTable from "@/components/redesign/Table/FunctionsTable.vue";
import TableComponent from "@/components/redesign/Table/tableComponent.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";
import dropdownComponent from "@/components/dropdown.vue";
import priceFormat from "@/mixins/priceFormat";
import FromMediaUploader from "@/components/redesign/FromMediaUploader.vue";
import FormItem from "@/components/redesign/FormItem.vue";
import DefaultNewInput from "@/components/redesign/defaultNewInput.vue";
import DefaultNewDropdown from "@/components/redesign/defaultNewDropdown.vue";
import DefaultDoubleRange from "@/components/redesign/defaultDoubleRange.vue";
import RoomsFilterCounter from "@/components/roomsFilterCounter.vue";
import SegmentedControl from "@/components/redesign/segmentedControl.vue";

export default {
  name: "WithoutInitialPaymentLayout",
  components: {
    SegmentedControl,
    RoomsFilterCounter,
    DefaultDoubleRange,
    DefaultNewDropdown,
    DefaultNewInput, FormItem, FromMediaUploader,
    TableComponent,
    IconComponent,
    FunctionsTable,
    DefaultButton,
    OptionsRow,
    ModalContainer, dropdownComponent},
  mixins: [priceFormat],
  data() {
    return {
      visibleModalCreateBPV:false,
      visibleRequiredFields: false,
      fields:{
        developments:null,
        typeObject:null,
        floors:null,
        square:null,
        rooms:[],
        first:null,
        sum_credit:null,
        fixed_loan_limit_switcher:null,
        fixed_loan_limit_value:null,
        loan_sum_markup:null
      },
      rooms:[0,1,2,3,4,5,6],
      stocks:[
       {
          "id": 1,
          "name": "ЖК «Альфа»",
          "children": [
            {
              "id": 1141,
              "object_id": 8,
              "development_id": null,
              "typeObject": "Квартира",
              "floor_min": null,
              "floor_max": null,
              "square_min": null,
              "square_max": null,
              "room_min": null,
              "room_max": null,
              "balcony": null,
              "sum_square": null,
              "percent_square": null,
              "sum_all": null,
              "percent_all": null,
              "price_square": null,
              "first": null,
              "first_percent": null,
              "min_metr": 107000,
              "nalog": 0,
              "sum_jku": null,
              "sum_nalog": null,
              "sum_credit": null,
              "bpv": 0,
              "created_at": "2024-05-25 11:16:51",
              "updated_at": "2024-10-11 11:33:40",
              "deleted_at": null,
              "only_bpv": null,
              "additional_markup": 0,
              "fixed_loan_limit_switcher": null,
              "fixed_loan_limit_value": null,
              "loan_sum_markup": null,
              "bpv_limit": null,
              "min_client_funds_percent": null
            }
          ]
        },
        {
          "id": 2,
          "name": "ЖК «Горный квартал»",
          "children": [
            {
              "id": 1141,
              "object_id": 8,
              "development_id": null,
              "typeObject": "Квартира",
              "floor_min": null,
              "floor_max": null,
              "square_min": null,
              "square_max": null,
              "room_min": null,
              "room_max": null,
              "balcony": null,
              "sum_square": null,
              "percent_square": null,
              "sum_all": null,
              "percent_all": null,
              "price_square": null,
              "first": null,
              "first_percent": null,
              "min_metr": 107000,
              "nalog": 0,
              "sum_jku": null,
              "sum_nalog": null,
              "sum_credit": null,
              "bpv": 0,
              "created_at": "2024-05-25 11:16:51",
              "updated_at": "2024-10-11 11:33:40",
              "deleted_at": null,
              "only_bpv": null,
              "additional_markup": 0,
              "fixed_loan_limit_switcher": null,
              "fixed_loan_limit_value": null,
              "loan_sum_markup": null,
              "bpv_limit": null,
              "min_client_funds_percent": null
            }
          ]
        },
       {
          "id": 4,
          "name": "ЖК «Дыхание»",
          "children": [
            {
              "id": 1141,
              "object_id": 8,
              "development_id": null,
              "typeObject": "Квартира",
              "floor_min": null,
              "floor_max": null,
              "square_min": null,
              "square_max": null,
              "room_min": null,
              "room_max": null,
              "balcony": null,
              "sum_square": null,
              "percent_square": null,
              "sum_all": null,
              "percent_all": null,
              "price_square": null,
              "first": null,
              "first_percent": null,
              "min_metr": 107000,
              "nalog": 0,
              "sum_jku": null,
              "sum_nalog": null,
              "sum_credit": null,
              "bpv": 0,
              "created_at": "2024-05-25 11:16:51",
              "updated_at": "2024-10-11 11:33:40",
              "deleted_at": null,
              "only_bpv": null,
              "additional_markup": 0,
              "fixed_loan_limit_switcher": null,
              "fixed_loan_limit_value": null,
              "loan_sum_markup": null,
              "bpv_limit": null,
              "min_client_funds_percent": null
            }
          ]
        }
      ],
      liters:{
        "132312": "Корпус 5",
        "132313": "Корпус 3",
        "132314": "Корпус 7",
        "132315": "Корпус 8",
        "132316": "Корпус 11",
        "132317": "Корпус 12",
        "132318": "Корпус 13",
        "132319": "Корпус 10",
        "132320": "Корпус 9",
        "132321": "Корпус 6",
        "132322": "Корпус 15",
        "132323": "Корпус 14",
        "132324": null,
        "132325": "Корпус 1",
        "132326": "Корпус 2"
      },
      searchQuery: null,
      activeTab: {},
      tabs: [
        {
          value: this.$i18n.t('Актуальное'),
          id: 1,
          icon: '/default-icons/task.svg'
        },
        {
          value: this.$i18n.t('Архив'),
          id: 2,
          icon: '/default-icons/history.svg'
        }
      ]
    }
  },
  methods:{
    optionSelectRoom(option){
      if(option>=4){
        let mas=[]
        this.rooms.map(el=>{
          if(el>=4)mas.push(el)
        })
        mas.map(element=>{
          if(this.fields.rooms.includes(element))this.fields.rooms=this.fields.rooms.filter(current=>current!==element)
          else this.fields.rooms.push(element)
        })
      } else {
        if(this.fields.rooms.includes(option))this.fields.rooms=this.fields.rooms.filter(el=>el!==option)
        else this.fields.rooms.push(option)
      }

    },
    addBlock(){
      this.visibleModalCreateBPV=true
    },
    saveBPV(){

    },
    editStock(){
      this.visibleModalCreateBPV=true
    },
    loadStocks(){

    },
    confirmActionStock(){

    },
  },
  mounted() {
    this.activeTab = this.tabs[0]
    this.loadStocks()
  }
}
</script>

<template>
  <div>
    <modal-container
        v-if="visibleModalCreateBPV"
        :title="'Добавление БПВ'"
        :confirmText="'Применить'"
        :width="'464px'"
        @close-modal="visibleModalCreateBPV = false"
        @confirm-option="saveBPV"
    >
      <form-item
          :title="'Строения'"
          :required="true"
          :notificationError="visibleRequiredFields"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <default-new-dropdown
            :tagMode="true"
            :noneHide="true"
            :multiple="true"
            :elements="[]"
            v-model="fields.developments"
            :none="$t('НеВыбрано')"
        />
      </form-item>
      <form-item
          :title="'Тип помещения'"
          :required="false"
          :notificationError="visibleRequiredFields"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <default-new-dropdown
            :noneHide="true"
            :multiple="false"
            :elements="['Квартира', 'Апартаменты', 'Коммерция', 'Загородные дома', 'Парковки', 'Кладовки', 'Подсобное помещение']"
            v-model="fields.typeObject"
            :none="$t('НеВыбрано')"
        />
      </form-item>
      <form-item
          :title="'Этажи'"
          :required="false"
          :notificationError="visibleRequiredFields"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <default-double-range
            :mobile="false"
            v-model="fields.floors"
            :options="{min:0,max:999999999}"
        />
      </form-item>
      <form-item
          :title="'Площадь'"
          :required="false"
          :notificationError="visibleRequiredFields"
          :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
      >
        <default-double-range
            :mobile="false"
            v-model="fields.square"
            :options="{min:0,max:999999999}"
        />
      </form-item>
      <form-item
          :title="'Количество комнат'"
          :required="false"
      >
        <rooms-filter-counter
            :options="rooms"
            :selected="fields.rooms"
            @select-deal="optionSelectRoom"
        />
      </form-item>
      <form-item
          :title="'Сумма БПВ'"
          :required="true"
      >
        <default-new-input
            v-model="fields.first"
            type="number"
            placeholder="20 000"/>
      </form-item>
      <form-item
          :title="'Минимальная сумма кредита'"
          :required="false"
      >
        <default-new-input
            v-model="fields.sum_credit"
            type="number"
            placeholder="20 000"/>
      </form-item>
      <form-item
          :title="'Фиксация ограничения суммы займа'"
          :required="false"
      >
        <segmented-control
            :stretched="true"
            :tabs="['Да', 'Нет', 'Не применяется']"
            v-model="fields.fixed_loan_limit_switcher"
        ></segmented-control>
      </form-item>
      <form-item
          :title="'Ограничение суммы займа'"
          :required="false"
      >
        <default-new-input
            v-model="fields.fixed_loan_limit_value"
            type="number"
            placeholder="20 000"/>
      </form-item>
      <form-item
          :title="'Наценка от суммы займа, %'"
          :required="false"
      >
        <default-new-input
            v-model="fields.loan_sum_markup"
            type="number"
            placeholder="20 000"/>
      </form-item>
    </modal-container>
    <functions-table
        :tabs="tabs"
        @update:activeTab="(value) => activeTab = value"
        @update:searchQuery="(value) => searchQuery = value"
        @add-row="addBlock"
    >
    </functions-table>
    <table-component
        v-model="stocks"
        :colspan="5"
    >
      <template #header-table>
        <th>{{ $t('Строение') }}</th>
        <th>{{ $t('Значение') }}</th>
        <th>{{ $t('Наценка') }}</th>
        <th>{{ $t('Комнатность') }}</th>
        <th>{{ $t('Площадь') }}</th>
      </template>
      <template #title-options="{ el }">
        <default-button
            @click-element=""
            type="outline"
            size="medium"
            leftIcon="/default-icons/info_fill.svg"
        />
      </template>
      <template #content-table-title="{ el }">
        {{el.name}}
      </template>
      <template #content-table-child="{child}">
        <td>
          <template v-if="liters && liters[child.development_id]">{{liters[child.development_id]}}</template>
          <template v-else>{{child.development_id}}</template>
        </td>
        <td></td>
        <td>
          <template v-if="child.sum_square && child.percent_square">{{priceFormat(child.sum_square)}}/{{ $t('м²') }} <br> {{child.percent_square}}%/{{ $t('м²') }}</template>
          <template v-else-if="child.sum_square">{{priceFormat(child.sum_square)}}/{{ $t('м²') }}</template>
          <template v-else-if="child.percent_square">{{child.percent_square}}%/{{ $t('м²') }}</template>
          <template v-else-if="child.sum_all">{{priceFormat(child.sum_all)}}</template>
          <template v-else-if="child.percent_all">{{child.percent_all}}%</template>
          <template v-else>{{ $t('назначить') }}</template>
        </td>
        <td>
          <template v-if="child.room_min">{{child.room_min}}</template>
          <template v-if="child.room_max"> - {{child.room_max}}</template>
        </td>
        <td>
          <template v-if="child.square_min">{{child.square_min}}</template>
          <template v-if="child.square_max"> - {{child.square_max}}</template>
        </td>
        <td class="options">
          <options-row
              :activeTab="activeTab?.id"
              @read-more="editStock(child)"
              @archive="confirmActionStock(child)"
          />
        </td>
      </template>
    </table-component>
  </div>
</template>

<style scoped lang="scss">

</style>