<script>
import IconComponent from "@/components/redesign/iconComponent.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";

export default {
  name: "tableComponent",
  components: {DefaultButton, IconComponent},
  model: {
    prop: 'data',
    event: 'change-data'
  },
  props: {
    visibleTitle: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: () => []
    },
    colspan: {
      type: Number,
      default: 2
    }
  },
  methods: {
    changeVisibleBlock(index) {
      console.log(index)
      this.data.splice(index, 1, {
        ...this.data[index],
        visibleChildren: (!this.data[index].visibleChildren) ?? true
      })
      this.$emit('change-data', this.data)
    }
  }
}
</script>

<template>
<div class="table-component">
  <table class="table">
    <thead>
    <tr class="header-table">
      <slot name="header-table"></slot>
    </tr>
    </thead>

    <tbody class="content-table" v-if="visibleTitle">
    <template  v-for="(el, index) in data">
      <tr v-if="el.children && !!el.children.length">
        <td :colspan="colspan">
          <div class="title" @click="changeVisibleBlock(index)">
            <slot name="content-table-title" :el="el"> </slot>
            <icon-component
                :class="[{active: el.visibleChildren ?? false}, 'hidden-block']"
                :icon="'/default-icons/expand_more.svg'"
                :color="'var(--colorIconAccent)'"
                :size="24"
            />
          </div>
        </td>
        <td class="options">
          <slot name="title-options" :el="el"></slot>
        </td>
      </tr>
      <tr v-for="child of !el.visibleChildren ? el.children : []">
        <slot name="content-table-child" :child="child"></slot>
      </tr>
    </template>
    </tbody>

    <tbody class="content-table" v-else>
      <tr v-for="(el, index) in data">
        <slot name="content-table" :el="el"></slot>
      </tr>
    </tbody>
  </table>
</div>
</template>

<style scoped lang="scss">
.table-component {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  .table {
    vertical-align: middle;
    width: 100%;
    .header-table {
      position: sticky;
    }
    .content-table {
      .title {
        font-size: 24px;
        display: flex;
        .hidden-block {
          cursor: pointer;
          width: fit-content;
          margin-left: 10px;
          transition: 0.5s ease transform;
          &.active {
            transform: rotate(-180deg);
          }
        }
      }
    }
    .options {
      text-align: center;
      cursor: pointer;
      border-left: 1px solid var(--colorStrokeSecondary);
    }
    tr {
      border-top: 1px solid var(--colorStrokeSecondary);
      border-bottom: 1px solid var(--colorStrokeSecondary);
      th {
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: var(--colorTextPrimary);
        padding: 12px 10px 12px 35px;
        white-space: nowrap;
        vertical-align: middle;
      }
      td {
        padding: 20px 10px 20px 35px;
      }
    }
  }
}
</style>