<template>
<div class="graphic-editor-image-loader">
  <div class="graphic-editor-image-loader-image-block">
    <img class="image-visual" v-if="source" :src="source" alt="" @click="chooseImage">
    <div class="image-block-overlay" @click="chooseImage">
      <transition name="fade" mode="out-in">
        <loader v-if="isLoading"></loader>
        <div v-else class="image-block-overlay-button">
          {{$t('ЗагрузитьИзображение')}}
          <input type="file" name="image" ref="imageLoader" @change="setImage">
        </div>
      </transition>
    </div>
  </div>
  <div class="graphic-editor-image-settings" v-if="false">
    <div class="graphic-editor-image-settings__field">
      <default-new-dropdown
          :searchVisible="false"
          :noneHide="true"
          :multiple="false"
          :elements="objectFitParams"
          v-model="objectFitImg"
      />
    </div>
    <default-button
        size="medium"
        type="tertiary"
        :width="'auto'"
        :left-icon="'/default-icons/rotate_90.svg'"
        @click-element="rotateImg"
    />
  </div>
  <template v-if="library">
    <div class="graphic-editor-image-separator">
      <div class="graphic-editor-image-separator__line"></div>
    </div>
    <default-button
        size="medium"
        type="outline"
        @click-element="chooseFromLibrary"
    >{{$t('ВыбратьИзБиблиотеки')}}</default-button>
  </template>
</div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import DefaultNewDropdown from "@/components/redesign/defaultNewDropdown.vue";

export default {
  name: "graphicEditorImageLoader",
  components: {DefaultNewDropdown, DefaultButton, Loader},
  inject:{
    library: {
      default: false
    }
  },
  data() {
    return {
      source: null,
      isLoading: false,
      objectFitImg:['cover'],
      objectFitParams:['cover', 'contain']
    }
  },
  props: {
    value: {
      type: String,
      default: null
    }
  },
  methods: {
    rotateImg(){

    },
    chooseFromLibrary(){

    },
    chooseImage() {
      if (this.isLoading) {
        return;
      }

      this.$refs.imageLoader.click()
    },
    setImage() {
      if (!this.$refs.imageLoader.files.length) {
        return;
      }

      this.isLoading = true;
      const file = this.$refs.imageLoader.files[0];
      const reader = new FileReader();
      reader.fileName = file.name;

      reader.onload = async (e) => {
        try {
          const {data} = await axios.post('https://cdn.itrielt.ru/upload/file', {
            file: {
              name: e.target.fileName,
              data: e.target.result
            }
          })

          this.source = data.url
        } catch (e) {
          console.error(e)
        } finally {
          this.isLoading = false;
        }
      }

      reader.readAsDataURL(file)
    }
  },
  mounted() {
    this.source = this.value
  },
  watch: {
    value(val) {
      this.source = val
    },
    source(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.graphic-editor-image-loader {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .graphic-editor-image-loader-image-block {
    width: 100%;
    height: 272px;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    .image-visual{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .image-block-overlay {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--colorOverlay);

      .image-block-overlay-button {
        border-radius: 8px;
        border: 1px solid var(--colorStrokeContrastInvariably);
        padding: 6px 12px;
        color: var(--colorTextContrastInvariably);
        text-align: center;
        font-variant-numeric: lining-nums tabular-nums;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.03px;

        input {
          display: none;
        }
      }
    }
  }
  .graphic-editor-image-separator{
    display: flex;
    height: 4px;
    justify-content: center;
    align-items: center;
    .graphic-editor-image-separator__line{
      background: var(--colorStrokeSecondary);
      height: 1px;
      width: 100%;
    }
  }
  .graphic-editor-image-settings{
    display: flex;
    gap: 8px;
    .graphic-editor-image-settings__field{
      width: 100%;
    }
  }
}
</style>